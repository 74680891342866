// deps
import { defineMessage } from "react-intl";
import {
  IoCartOutline,
  IoCheckmark,
  IoCloseOutline,
  IoEllipsisHorizontalCircle,
  IoMailOutline,
  IoPersonOutline,
  IoWarningOutline,
} from "react-icons/io5";

export const BOOKINGS_NATURE_VALUE_PRO = "pro";
export const BOOKINGS_NATURE_VALUE_PAR = "par";

export const BOOKINGS_NATURES = {
  [BOOKINGS_NATURE_VALUE_PRO]: {
    id: BOOKINGS_NATURE_VALUE_PRO,
    color2: "#F95E24",
  },
  [BOOKINGS_NATURE_VALUE_PAR]: {
    id: BOOKINGS_NATURE_VALUE_PAR,
    color2: "#008BD5",
  },
};

export const BOOKINGS_NATURE_LIST = Object.values(BOOKINGS_NATURES);

// Source.

export const BOOKINGS_SOURCE_VALUE_WEB = "web";
export const BOOKINGS_SOURCE_VALUE_CUSTOMER = "customer";
export const BOOKINGS_SOURCE_VALUE_PARTNER = "partner";

export const BOOKINGS_SOURCES = {
  [BOOKINGS_SOURCE_VALUE_WEB]: {
    id: BOOKINGS_SOURCE_VALUE_WEB,
    color2: "#008BD5",
  },
  [BOOKINGS_SOURCE_VALUE_CUSTOMER]: {
    id: BOOKINGS_SOURCE_VALUE_CUSTOMER,
    color2: "#65B733",
  },
  [BOOKINGS_SOURCE_VALUE_PARTNER]: {
    id: BOOKINGS_SOURCE_VALUE_PARTNER,
    color2: "#666",
  },
};

export const BOOKINGS_SOURCE_LIST = Object.values(BOOKINGS_SOURCES);

export const bookingsSourceListMessage = defineMessage({
  defaultMessage: `{source, select,
    partner {Site partenaire}
    customer {Site propriétaire}
    web {Site Clévacances}
    other {{source}}
  }`,
});

// État.

export const BOOKINGS_STATE_VALUE_DRAFT = "draft";
export const BOOKINGS_STATE_VALUE_CONFIRMED = "confirmed";
export const BOOKINGS_STATE_VALUE_CANCELLED = "cancelled";
export const BOOKINGS_STATE_VALUE_FINISHED = "finished";

export const BOOKINGS_STATES = {
  [BOOKINGS_STATE_VALUE_DRAFT]: {
    id: BOOKINGS_STATE_VALUE_DRAFT,
    colors: ["orange.600", "orange.300"],
    selectable: true,
    icon: IoEllipsisHorizontalCircle,
  },
  [BOOKINGS_STATE_VALUE_CONFIRMED]: {
    id: BOOKINGS_STATE_VALUE_CONFIRMED,
    colors: ["green.600", "green.300"],
    selectable: true,
    icon: IoCheckmark,
  },
  [BOOKINGS_STATE_VALUE_CANCELLED]: {
    id: BOOKINGS_STATE_VALUE_CANCELLED,
    colors: ["red.600", "red.300"],
    selectable: true,
    icon: IoCloseOutline,
  },
  [BOOKINGS_STATE_VALUE_FINISHED]: {
    id: BOOKINGS_STATE_VALUE_FINISHED,
    colors: ["green.600", "green.300"],
    selectable: true,
    icon: IoCheckmark,
  },
};

// For view any filtering only
export const BOOKINGS_STATE_VALUE_CANCELLATION_PENDING = "cancellation_pending";

export const BOOKINGS_STATE_VALUE_PENDING_ISSUE = "pending_issue";

export const BOOKINGS_STATE_VALUE_WITH_PENDING_ISSUE = "with_issue";

// For Virtual State (for list only -> we use different kinds of informations from a booking to determine states that aren't given by the backend)

export const BOOKINGS_VIRTUAL_STATE_VALUE_WAITING_FOR_CUSTOMER =
  "waiting_for_customer";

export const BOOKINGS_VIRTUAL_STATE_VALUE_CANCELLATION_CLAIMED =
  "cancellation_claimed";

export const bookingsFilterListStateMessage = defineMessage({
  defaultMessage: `{state, select,
    confirmed {En cours}
    pending_issue {En litige}
    finished {Terminées}
    cancelled {Annulées}
    draft {Brouillons}
    cancellation_pending {En attente d'annulation}
    other {{state}}
  }`,
});

export const BOOKINGS_FILTER_LIST_STATES = {
  [BOOKINGS_STATE_VALUE_CONFIRMED]: {
    id: BOOKINGS_STATE_VALUE_CONFIRMED,
    colors: ["green.600", "green.300"],
    selectable: true,
  },
  [BOOKINGS_STATE_VALUE_PENDING_ISSUE]: {
    id: BOOKINGS_STATE_VALUE_PENDING_ISSUE,
    colors: ["red.600", "red.300"],
    selectable: false,
  },
  [BOOKINGS_STATE_VALUE_FINISHED]: {
    id: BOOKINGS_STATE_VALUE_FINISHED,
    colors: ["red.600", "red.300"],
    selectable: true,
  },
  [BOOKINGS_STATE_VALUE_CANCELLED]: {
    id: BOOKINGS_STATE_VALUE_CANCELLED,
    colors: ["red.600", "red.300"],
    selectable: true,
  },
  [BOOKINGS_STATE_VALUE_DRAFT]: {
    id: BOOKINGS_STATE_VALUE_DRAFT,
    colors: ["red.600", "red.300"],
    selectable: true,
  },
  [BOOKINGS_STATE_VALUE_CANCELLATION_PENDING]: {
    id: BOOKINGS_STATE_VALUE_CANCELLATION_PENDING,
    colors: ["gray.600", "gray.300"],
    selectable: true,
  },
};

export const BOOKINGS_FILTER_LIST_STATE_LIST = Object.values(
  BOOKINGS_FILTER_LIST_STATES,
);

export const BOOKINGS_ACCOUNT_FILTER_LIST_STATE_LIST = Object.values(
  BOOKINGS_FILTER_LIST_STATES,
).filter(
  (state) =>
    state.id !== BOOKINGS_STATE_VALUE_DRAFT &&
    state.id !== BOOKINGS_STATE_VALUE_CANCELLATION_PENDING,
);

export const bookingsStateMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    confirmed {confirmée}
    cancelled {annulée}
    finished {terminée}
    with_issue {litige ouvert}
    other {{state}}
  }`,
});

export const bookingsStateWithVirtualStatesMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    confirmed {confirmée}
    cancelled {annulée}
    finished {terminée}
    with_issue {litige ouvert}
    waiting_for_customer {en attente propriétaire}
    cancellation_claimed {demande d'annulation sans frais (COVID)}
    other {{state}}
  }`,
});

export const BOOKINGS_STATES_WITH_VIRTUAL_STATES = {
  [BOOKINGS_STATE_VALUE_DRAFT]: {
    id: BOOKINGS_STATE_VALUE_DRAFT,
    colors: ["orange.600", "orange.300"],
    selectable: true,
    icon: IoEllipsisHorizontalCircle,
  },
  [BOOKINGS_STATE_VALUE_CONFIRMED]: {
    id: BOOKINGS_STATE_VALUE_CONFIRMED,
    colors: ["green.600", "green.300"],
    selectable: true,
    icon: IoCheckmark,
  },
  [BOOKINGS_STATE_VALUE_CANCELLED]: {
    id: BOOKINGS_STATE_VALUE_CANCELLED,
    colors: ["red.600", "red.300"],
    selectable: true,
    icon: IoCloseOutline,
  },
  [BOOKINGS_STATE_VALUE_FINISHED]: {
    id: BOOKINGS_STATE_VALUE_FINISHED,
    colors: ["green.600", "green.300"],
    selectable: true,
    icon: IoCheckmark,
  },
  [BOOKINGS_STATE_VALUE_WITH_PENDING_ISSUE]: {
    id: BOOKINGS_STATE_VALUE_WITH_PENDING_ISSUE,
    colors: ["purple.600", "purple.300"],
    selectable: false,
    icon: IoWarningOutline,
  },
  [BOOKINGS_VIRTUAL_STATE_VALUE_WAITING_FOR_CUSTOMER]: {
    id: BOOKINGS_VIRTUAL_STATE_VALUE_WAITING_FOR_CUSTOMER,
    colors: ["blue.600", "blue.300"],
    selectable: true,
    icon: IoCheckmark,
  },
  [BOOKINGS_VIRTUAL_STATE_VALUE_CANCELLATION_CLAIMED]: {
    id: BOOKINGS_VIRTUAL_STATE_VALUE_CANCELLATION_CLAIMED,
    colors: ["red.600", "red.300"],
    selectable: true,
    icon: IoCloseOutline,
  },
};

export const BOOKINGS_STATE_LIST = Object.values(BOOKINGS_STATES);

export const BOOKINGS_VIRTUAL_STATE_LIST = Object.values(
  BOOKINGS_STATES_WITH_VIRTUAL_STATES,
);

export const BOOKINGS_SEARCH_STATES = {
  [BOOKINGS_STATE_VALUE_CONFIRMED]: {
    id: BOOKINGS_STATE_VALUE_CONFIRMED,
  },
  [BOOKINGS_STATE_VALUE_CANCELLED]: {
    id: BOOKINGS_STATE_VALUE_CANCELLED,
  },
  [BOOKINGS_STATE_VALUE_FINISHED]: {
    id: BOOKINGS_STATE_VALUE_FINISHED,
  },
  [BOOKINGS_STATE_VALUE_WITH_PENDING_ISSUE]: {
    id: BOOKINGS_STATE_VALUE_WITH_PENDING_ISSUE,
  },
};

export const BOOKINGS_SEARCH_STATE_LIST = Object.values(BOOKINGS_SEARCH_STATES);

// Raison d’annulation d’une réservation.
export const BOOKINGS_CANCELLATION_REASON_VALUE_EXPIRED = "expired";
export const BOOKINGS_CANCELLATION_REASON_VALUE_REFUSED = "refused";
export const BOOKINGS_CANCELLATION_REASON_VALUE_GUEST = "guest";
export const BOOKINGS_CANCELLATION_REASON_VALUE_CUSTOMER = "customer";
export const BOOKINGS_CANCELLATION_REASON_VALUE_OTHER = "other";
export const BOOKINGS_CANCELLATION_REASON_VALUE_PAYMENT_MISSING =
  "payment_missing";
export const BOOKINGS_CANCELLATION_REASON_VALUE_COVID = "covid";

export const BOOKINGS_CANCELLATION_REASONS = {
  [BOOKINGS_CANCELLATION_REASON_VALUE_EXPIRED]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_EXPIRED,
    agentSelectable: false,
    color2: "#888",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_REFUSED]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_REFUSED,
    agentSelectable: false,
    color2: "#B7339A",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_GUEST]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_GUEST,
    agentSelectable: true,
    color2: "#008BD5",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_CUSTOMER]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_CUSTOMER,
    agentSelectable: true,
    color2: "#F85D23",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_OTHER]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_OTHER,
    agentSelectable: true,
    color2: "#333",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_PAYMENT_MISSING]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_PAYMENT_MISSING,
    agentSelectable: false,
    color2: "#65B733",
  },
  [BOOKINGS_CANCELLATION_REASON_VALUE_COVID]: {
    id: BOOKINGS_CANCELLATION_REASON_VALUE_COVID,
    agentSelectable: false,
    color2: "#B73333",
  },
};

export const BOOKINGS_CANCELLATION_REASON_LIST = Object.values(
  BOOKINGS_CANCELLATION_REASONS,
);

// Type d’échéance.

/** Acompte voyageur */
export const BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_PAYMENT = "guest_payment";
/** Remboursement voyageur */
export const BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_REFUND = "guest_refund";
/** Versement propriétaire */
export const BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_PAYMENT =
  "customer_payment";
/** Remboursement propriétaire */
export const BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_REFUND = "customer_refund";
/** paiement ville **/
export const BOOKINGS_DEADLINES_TYPE_VALUE_CITY_PAYMENT = "city_payment";
/** retenu ts ville */
export const BOOKINGS_DEADLINES_TYPE_VALUE_CITY_REFUND = "city_refund";
/** retenue sur environment */
export const BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_REFUND =
  "environment_refund";
/** paiment territoire */
export const BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_PAYMENT =
  "environment_payment";
/** paiement partenaire */
export const BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_PAYMENT = "partner_payment";
/** retenue partenaire */
export const BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_REFUND = "partner_refund";

export const BOOKINGS_DEADLINES_TYPES = {
  [BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_PAYMENT]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_PAYMENT,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_REFUND]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_REFUND,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_PAYMENT]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_PAYMENT,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_REFUND]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_CUSTOMER_REFUND,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_CITY_PAYMENT]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_CITY_PAYMENT,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_CITY_REFUND]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_CITY_REFUND,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_REFUND]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_REFUND,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_PAYMENT]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_ENVIRONMENT_PAYMENT,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_PAYMENT]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_PAYMENT,
  },
  [BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_REFUND]: {
    id: BOOKINGS_DEADLINES_TYPE_VALUE_PARTNER_REFUND,
  },
};

export const BOOKINGS_DEADLINES_TYPE_LIST = Object.values(
  BOOKINGS_DEADLINES_TYPES,
);

// État d’une échéance.

export const BOOKINGS_DEADLINES_STATE_VALUE_PENDING = "pending";
export const BOOKINGS_DEADLINES_STATE_VALUE_IN_PROGRESS = "in_progress";
export const BOOKINGS_DEADLINES_STATE_VALUE_SOLDED = "solded";
export const BOOKINGS_DEADLINES_STATE_VALUE_CANCELLED = "cancelled";

export const BOOKINGS_DEADLINES_STATES = {
  [BOOKINGS_DEADLINES_STATE_VALUE_PENDING]: {
    id: BOOKINGS_DEADLINES_STATE_VALUE_PENDING,
  },
  [BOOKINGS_DEADLINES_STATE_VALUE_IN_PROGRESS]: {
    id: BOOKINGS_DEADLINES_STATE_VALUE_IN_PROGRESS,
  },
  [BOOKINGS_DEADLINES_STATE_VALUE_SOLDED]: {
    id: BOOKINGS_DEADLINES_STATE_VALUE_SOLDED,
  },
  [BOOKINGS_DEADLINES_STATE_VALUE_CANCELLED]: {
    id: BOOKINGS_DEADLINES_STATE_VALUE_CANCELLED,
  },
};

export const BOOKINGS_DEADLINES_STATE_LIST = Object.values(
  BOOKINGS_DEADLINES_STATES,
);

// Méthode de règlement d’une échéance.

export const BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONLINE = "online";
export const BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONSPOT = "on_spot";

export const BOOKINGS_DEADLINES_PAYMENT_MODES = {
  [BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONLINE]: {
    id: BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONLINE,
  },
  [BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONSPOT]: {
    id: BOOKINGS_DEADLINES_PAYMENT_MODE_VALUE_ONSPOT,
  },
};

export const BOOKINGS_DEADLINES_PAYMENT_MODE_LIST = Object.values(
  BOOKINGS_DEADLINES_PAYMENT_MODES,
);

// État d’une confirmation propriétaire.

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_PENDING = "pending";
export const BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_CONFIRMED = "confirmed";
export const BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_REFUSED = "refused";
// statut virtuel pour déterminer qui a refusé la réservation
export const BOOKINGS_OWNER_ACKNOWLEDGMENT_VIRTUAL_STATE_VALUE_REFUSED_BY_CUSTOMER =
  "refused_by_customer";
export const BOOKINGS_OWNER_ACKNOWLEDGMENT_VIRTUAL_STATE_VALUE_REFUSED_BY_SYSTEM =
  "refused_by_system";

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_STATES = {
  [BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_PENDING]: {
    id: BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_PENDING,
  },
  [BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_CONFIRMED]: {
    id: BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_CONFIRMED,
  },
  [BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_REFUSED]: {
    id: BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_REFUSED,
  },
};

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_LIST = Object.values(
  BOOKINGS_OWNER_ACKNOWLEDGMENT_STATES,
);

// workflow

export { CARTS_WORKFLOW_VALUE_DIRECT as BOOKINGS_WORKFLOW_VALUE_DIRECT } from "./carts";
export { CARTS_WORKFLOW_VALUE_SYSTEM as BOOKINGS_WORKFLOW_VALUE_SYSTEM } from "./carts";
export { CARTS_WORKFLOW_VALUE_PARTNER as BOOKINGS_WORKFLOW_VALUE_PARTNER } from "./carts";
export { CARTS_WORKFLOWS as BOOKINGS_WORKFLOWS } from "./carts";
export { CARTS_WORKFLOW_LIST as BOOKINGS_WORKFLOW_LIST } from "./carts";

export const CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT = "direct";
export const CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM = "system";
export const CARTS_BOOKINGS_WORKFLOW_VALUE_PARTNER = "partner";

export const CARTS_BOOKINGS_WORKFLOWS = {
  [CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM]: {
    id: CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM,
    icon: IoCartOutline,
    colors: ["blue.600", "blue.300"],
    colorScheme: "blue",
  },
  [CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT]: {
    id: CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT,
    icon: IoMailOutline,
    colors: ["purple.600", "purple.300"],
    colorScheme: "purple",
  },
  [CARTS_BOOKINGS_WORKFLOW_VALUE_PARTNER]: {
    id: CARTS_BOOKINGS_WORKFLOW_VALUE_PARTNER,
    icon: IoPersonOutline,
    colors: ["orange.600", "orange.300"],
    colorScheme: "orange",
  },
};

export const CARTS_BOOKINGS_WORKFLOWS_SYSTEM_OR_DIRECT = {
  [CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM]: {
    id: CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM,
    icon: IoCartOutline,
    colors: ["blue.600", "blue.300"],
    colorScheme: "blue",
  },
  [CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT]: {
    id: CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT,
    icon: IoMailOutline,
    colors: ["purple.600", "purple.300"],
    colorScheme: "purple",
  },
};

export const CARTS_BOOKINGS_WORKFLOW_LIST = Object.values(
  CARTS_BOOKINGS_WORKFLOWS,
);

export const cartsBookingsWorkflowMessage = defineMessage({
  defaultMessage: `{workflow, select,
    direct {réservation directe}
    system {réservation en ligne}
    partner {réservation partenaire}
    other {{workflow}}
  }`,
});

export const cartsBookingsWorkflowMessageCount = defineMessage({
  defaultMessage: `{workflow, select,
    direct {{count, plural, =0 {aucune réservation directe} one {1 réservation directe} other {# réservations directes}}}
    system {{count, plural, =0 {aucune réservation en ligne} one {1 réservation en ligne} other {# réservations en ligne}}}
    partner {{count, plural, =0 {aucune réservation partenaire} one {1 réservation partenaire} other {# réservations partenaire}}}
    other {{workflow}}
  }`,
});

export const cartsBookingsStateMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    confirmed {confirmé}
    finalized {finalisé}
    cancelled {annulé}
    other {{state}}
  }`,
});

// Action pour la confirmation propriétaire

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_REJECT = "reject";
export const BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_ACCEPT = "accept";

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTIONS = {
  [BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_REJECT]: {
    id: BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_REJECT,
  },
  [BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_ACCEPT]: {
    id: BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_VALUE_ACCEPT,
  },
};

export const BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTION_LIST = Object.values(
  BOOKINGS_OWNER_ACKNOWLEDGMENT_ACTIONS,
);

// Méthode d’envoi d’un fichier.

export const BOOKINGS_DOCUMENTS_TRANSFER_METHOD_VALUE_EMAIL = "email";

export const BOOKINGS_DOCUMENTS_TRANSFER_METHODS = {
  [BOOKINGS_DOCUMENTS_TRANSFER_METHOD_VALUE_EMAIL]: {
    id: BOOKINGS_DOCUMENTS_TRANSFER_METHOD_VALUE_EMAIL,
  },
};

export const BOOKINGS_DOCUMENTS_TRANSFER_METHOD_LIST = Object.values(
  BOOKINGS_DOCUMENTS_TRANSFER_METHODS,
);

// Tri.

export const BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_ASC = "checkin_asc";
export const BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_DESC = "checkin_desc";
export const BOOKINGS_SEARCH_SORT_VALUE_CREATED_ASC = "created_asc";
export const BOOKINGS_SEARCH_SORT_VALUE_CREATED_DESC = "created_desc";

export const bookingsSortMessage = defineMessage({
  defaultMessage: `{sort, select,
    checkin_asc {Date d'arrivée croissante}
    checkin_desc {Date d'arrivée décroissante}
    created_asc {Date de réservation croissante}
    created_desc {Date de réservation décroissante}
    other {{sort}}
  }`,
});

export const BOOKINGS_SEARCH_SORTS = {
  [BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_ASC]: {
    id: BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_ASC,
    label: defineMessage({
      defaultMessage: "Date d'arrivée croissante",
    }),
  },
  [BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_DESC]: {
    id: BOOKINGS_SEARCH_SORT_VALUE_CHECKIN_DESC,
    label: defineMessage({
      defaultMessage: "Date d'arrivée décroissante",
    }),
  },
  [BOOKINGS_SEARCH_SORT_VALUE_CREATED_ASC]: {
    id: BOOKINGS_SEARCH_SORT_VALUE_CREATED_ASC,
    label: defineMessage({
      defaultMessage: "Date de réservation croissante",
    }),
  },
  [BOOKINGS_SEARCH_SORT_VALUE_CREATED_DESC]: {
    id: BOOKINGS_SEARCH_SORT_VALUE_CREATED_DESC,
    label: defineMessage({
      defaultMessage: "Date de réservation décroissante",
    }),
  },
};

export const BOOKINGS_SORT_LIST = Object.values(BOOKINGS_SEARCH_SORTS);

export const BOOKINGS_DATE_MODE_VALUE_TRIP = "trip";
export const BOOKINGS_DATE_MODE_VALUE_CREATED = "created";

export const BOOKINGS_DATE_MODES = {
  [BOOKINGS_DATE_MODE_VALUE_TRIP]: {
    id: BOOKINGS_DATE_MODE_VALUE_TRIP,
  },
  [BOOKINGS_DATE_MODE_VALUE_CREATED]: {
    id: BOOKINGS_DATE_MODE_VALUE_CREATED,
  },
};

export const BOOKINGS_DATE_MODE_LIST = Object.values(BOOKINGS_DATE_MODES);

export const bookingsDateModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    trip {Date de séjour}
    created {Date de création}
    other {{mode}}
  }`,
});

// Mode de la taxe de séjour

export const BOOKINGS_TOURIST_TAX_MODE_VALUE_AUTO = "auto";
export const BOOKINGS_TOURIST_TAX_MODE_VALUE_MANUAL = "manual";

export const BOOKINGS_TOURIST_TAX_MODES = {
  [BOOKINGS_TOURIST_TAX_MODE_VALUE_AUTO]: {
    id: BOOKINGS_TOURIST_TAX_MODE_VALUE_AUTO,
  },
  [BOOKINGS_TOURIST_TAX_MODE_VALUE_MANUAL]: {
    id: BOOKINGS_TOURIST_TAX_MODE_VALUE_MANUAL,
  },
};

export const BOOKINGS_TOURIST_TAX_MODE_LIST = Object.values(
  BOOKINGS_TOURIST_TAX_MODES,
);

// claim reason
export const BOOKINGS_CANCELLATION_CLAIM_REASON_VALUE_COVID = "covid";

export const BOOKINGS_CANCELLATION_CLAIM_REASONS = {
  [BOOKINGS_CANCELLATION_CLAIM_REASON_VALUE_COVID]: {
    id: BOOKINGS_CANCELLATION_CLAIM_REASON_VALUE_COVID,
  },
};

export const BOOKINGS_CANCELLATION_CLAIM_REASON_LIST = Object.values(
  BOOKINGS_CANCELLATION_CLAIM_REASONS,
);

// claim source
export const BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_GUEST = "guest";
export const BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_CUSTOMER = "customer";

export const BOOKINGS_CANCELLATION_CLAIM_SOURCES = {
  [BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_GUEST]: {
    id: BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_GUEST,
  },
  [BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_CUSTOMER]: {
    id: BOOKINGS_CANCELLATION_CLAIM_SOURCE_VALUE_CUSTOMER,
  },
};

// partner booking workflow

export { PARTNERS_BOOKING_WORKFLOW_VALUE_EXTERNAL as BOOKINGS_WORKFLOW_VALUE_EXTERNAL } from "./partners";
export { PARTNERS_BOOKING_WORKFLOW_VALUE_COMPLETE as BOOKINGS_WORKFLOW_VALUE_COMPLETE } from "./partners";

// booking reject oack
export const BOOKINGS_REJECT_OACK_VALUE_NOT_AVAILABLE = "not_available";
export const BOOKINGS_REJECT_OACK_VALUE_ALREADY_BOOKED = "already_booked";
export const BOOKINGS_REJECT_OACK_VALUE_OTHER = "other";

export const BOOKINGS_REJECT_OACK_VALUES = {
  [BOOKINGS_REJECT_OACK_VALUE_NOT_AVAILABLE]: {
    id: BOOKINGS_REJECT_OACK_VALUE_NOT_AVAILABLE,
    shouldOpenedText: false,
  },
  [BOOKINGS_REJECT_OACK_VALUE_ALREADY_BOOKED]: {
    id: BOOKINGS_REJECT_OACK_VALUE_ALREADY_BOOKED,
    shouldOpenedText: false,
  },
};

export const BOOKINGS_REJECT_OACK_VALUES_LIST = Object.values(
  BOOKINGS_REJECT_OACK_VALUES,
);

export const CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_PENDING = "pending";
export const CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_CONFIRMED = "confirmed";
export const CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_REFUSED = "refused";

export const CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATES = {
  [CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_PENDING]: {
    id: CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_PENDING,
  },
  [CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_CONFIRMED]: {
    id: CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_CONFIRMED,
  },
  [CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_REFUSED]: {
    id: CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_REFUSED,
  },
};

export const CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATES_LIST = Object.values(
  CARTS_BOOKINGS_OWNER_ACKNOWLEDGMENT_STATES,
);

// Type of document that can be downloaded in booking detail
export const CARTS_BOOKING_DOCUMENT_TYPE_VALUE_CONTRACT = "contract";

export const CARTS_BOOKING_DOCUMENT_TYPE_VALUE_ROOM_DOCUMENT = "room_document";

export const CARTS_BOOKING_DOCUMENT_TYPE_VALUE_INVOICE = "invoice";

export const CARTS_BOOKING_DOCUMENT_TYPE_VALUE_DIRECT_INVOICE =
  "direct_invoice";

export const CARTS_BOOKING_DOCUMENT_TYPE_VALUE_ON_SPOT_INVOICE =
  "on_spot_invoice";

export const cartsBookingsOwnerAcknowledgmentStatesMessage = defineMessage({
  defaultMessage: `{ownerAcknowledgmentState, select,
    pending {En attente de la confirmation}
    confirmed {Confirmé par le propriétaire}
    refused {Refusé par le propriétaire}
    other {{ownerAcknowledgmentState}}
  }`,
});

export const bookingsNatureMessage = defineMessage({
  defaultMessage: `{nature, select,
    par {particulier}
    pro {professionnel}
    other {{nature}}
  }`,
});

export {
  cartsWorkflowMessage as bookingsWorkflowMessage,
  cartsWorkflowMessageCount as bookingsWorkflowMessageCount,
} from "./carts";

export const bookingsSourceMessage = defineMessage({
  defaultMessage: `{source, select,
    web {site Clévacances.com}
    customer {site propriétaire}
    partner {site partenaire}
    other {{source}}
  }`,
});

export const bookingsDeadlineStateMessage = defineMessage({
  defaultMessage: `{deadlineState, select,
    pending {en attente}
    in_progress {en cours de paiement}
    solded {réglée}
    cancelled {annulée}
    other {{deadlineState}}
  }`,
});

export const bookingsDeadlinesModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    guest_payment {paiement voyageur}
    guest_refund {remboursement voyageur}
    customer_payment {paiement propriétaire}
    customer_refund {remboursement propriétaire}
    other {{mode}}
  }`,
});

export const bookingsDocumentTransferMethodMessage = defineMessage({
  defaultMessage: `{method, select,
    email {e–mail}
    other {{method}}
  }`,
});

export const bookingsSearchSortMessage = defineMessage({
  defaultMessage: `{sort, select,
    checkin_asc {Date d’arrivée croissante}
    checkin_desc {Date d’arrivée décroissante}
    created_asc {Date de création croissante}
    created_desc {Date de création décroissante}
    other {{sort}}
  }`,
});

export const bookingsCancellationReasonMessage = defineMessage({
  defaultMessage: `{cancellationReason, select,
    expired {réservation expirée}
    refused {réservation refusée}
    guest {réservation annulée par le client}
    customer {réservation annulée par le propriétaire}
    other {sans frais}
    payment_missing {paiement manquant}
    covid {covid-19}
    refused_by_customer {réservation refusée par le propriétaire}
    refused_by_system {réservation refusée par le système}
  }`,
});

export const bookingsCancellationReasonForGuestMessage = defineMessage({
  defaultMessage: `{cancellationReason, select,
    expired {réservation expirée}
    refused {réservation refusée}
    guest {vous avez annulé la réservation}
    customer {réservation annulée par le propriétaire}
    other {sans frais}
    payment_missing {paiement manquant}
    covid {covid-19}
    refused_by_customer {réservation refusée par le propriétaire}
    refused_by_system {réservation refusée par le système}
  }`,
});

export const bookingsCancellationReasonForCustomerMessage = defineMessage({
  defaultMessage: `{cancellationReason, select,
    expired {réservation expirée}
    refused {réservation refusée}
    guest {réservation annulée par le client}
    customer {vous avez annulé la réservation}
    other {sans frais}
    payment_missing {paiement manquant}
    covid {covid-19}
    refused_by_customer {vous avez refusé la réservation}
    refused_by_system {réservation refusée par le système}
  }`,
});

export const bookingsCancellationClaimSourceMessage = defineMessage({
  defaultMessage: `{cancellationClaimSource, select,
    guest {par le client}
    customer {par le propriétaire}
    other {{cancellationClaimSource}}
  }`,
});

export const bookingsCancellationClaimSourceDetailedMessage = defineMessage({
  defaultMessage: `{cancellationClaimSource, select,
    guest {annulation faite par le client}
    customer {annulation faite par le propriétaire}
    other {{cancellationClaimSource}}
  }`,
});

export const bookingsCancellationClaimReasonMessage = defineMessage({
  defaultMessage: `{cancellationClaimReason, select,
    covid {Annulation suite à une restriction gouvernementale exceptionnelle liée à l'épidémie de covid19 comme un confinement ou des restrictions de déplacement entrainant une incapacité pour le locataire de se rendre dans l'hébergement}
    other {{cancellationClaimReason}}
  }`,
});

export const bookingsCancellationClaimReasonShortMessage = defineMessage({
  defaultMessage: `{cancellationClaimReason, select,
    covid {covid}
    other {{cancellationClaimReason}}
  }`,
});

export const bookingsRejectOackReasonMessage = defineMessage({
  defaultMessage: `{reason, select,
    not_available {l'hébergement n'est plus disponible pour cette période}
    already_booked {l'hébergement est en cours d'option de réservation par un autre voyageur}
    other {autre}
  }`,
});
